<template>
  <div :class="`personal_${$store.state.mode}`">
    <template v-if="$store.state.mode == 'pc'">
      <template v-if="tabType == 4">
        <div class="delivery_tabs">
          <button v-for="item in deliveryTabs" @click="handleCategory(item.type)" :key="item.type" :class="['delivery_item', { active: item.type == category }]">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </button>
        </div>
        <div class="delivery_list" v-if="deliveryRecordsList.length">
          <goodsCard v-for="(item, index) in deliveryRecordsList" :key="index" :goodsItem="item" :keyMap="{ levelImg: 'ornamentLevelImg', imageUrl: 'ornamentImg', name: 'ornamentName', price: 'ornamentsPrice' }" />
        </div>
        <div class="not_data" v-else>--暂无数据--</div>
        <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="deliveryRecordTotal" :current-page="deliveryRecordsPage.page" :page-size="deliveryRecordsPage.size" @current-change="getlisttihuo" />
      </template>
      <template v-if="tabType == 5">
        <div class="records_list">
          <div class="recharge_records">
            <h2>充值记录</h2>
            <el-table rowKey="id" :data="rechargeList" class="table_list" style="width: 100%" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell" v-loading="rechargeLoading">
              <el-table-column prop="goodsPrice" label="金额" min-width="18%">
                <template slot-scope="scope">
                  <price :price="scope.row.goodsPrice || 0" />
                </template>
              </el-table-column>
              <el-table-column prop="payType" label="渠道" min-width="18%">
                <template slot-scope="scope">
                  {{ payType(scope.row.payType) }}
                </template>
              </el-table-column>
              <el-table-column prop="orderId" label="单号" min-width="32%" />
              <el-table-column prop="updateTime" label="时间" min-width="32%" />
              <div slot="empty">--暂无充值信息--</div>
            </el-table>
            <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="rechargeTotal" :current-page="rechargeRecordsPage.page" :page-size="rechargeRecordsPage.size" @current-change="getRechangeList" />
          </div>
          <div class="revenue_records">
            <h2>收支明细</h2>
            <div class="taps">
              <div :class="revenueListPage.moneyType == 1 ? 'tap active' : 'tap'" @click="handlerevenueListType(1)">金币</div>
              <div :class="revenueListPage.moneyType == 2 ? 'tap active' : 'tap'" @click="handlerevenueListType(2)">积分</div>
            </div>
            <el-table rowKey="id" :data="revenueList" class="table_list" style="width: 100%" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell" v-loading="revenueLoading">
              <el-table-column v-if="revenueListPage.moneyType == 1" prop="amount" label="金额" min-width="20%" />
              <el-table-column v-else prop="credits" label="金额" min-width="20%" />
              <el-table-column prop="remark" label="用途" min-width="30%" />
              <el-table-column prop="amount" label="状态" min-width="20%">
                <template slot-scope="scope">
                  <div v-if="scope.row.type == 0" style="color: red">支出</div>
                  <div v-else :style="{ color: $main }">收入</div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="时间" min-width="30%" />
              <div slot="empty">--暂无交易信息--</div>
            </el-table>
            <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="revenueTotal" :current-page="revenueListPage.page" :page-size="revenueListPage.size" @current-change="getRevenueList" />
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <headerTop :title="delivery ? '提货信息' : '个人明细'" />
      <template v-if="delivery">
        <div class="main_i_top center">
          <div class="btn center" v-for="item in deliveryTabs" :key="item.type" @click="handleCategory(item.type)" :class="{ active: category == item.type }">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="delivery_box" @scroll="deliveryRecordsScroll">
          <div class="delivery_list" v-if="deliveryRecordsList.length">
            <goodsCard v-for="(item, index) in deliveryRecordsList" :key="index" :goodsItem="item" :keyMap="{ levelImg: 'ornamentLevelImg', imageUrl: 'ornamentImg', name: 'ornamentName', price: 'ornamentsPrice' }" />
          </div>
          <div class="over_text" v-if="category != 2">{{ deliveryRecordsIsOver }}</div>
        </div>
      </template>
      <template v-else>
        <div class="tab space_around">
          <div v-for="(item, index) in menuList" :key="index" @click.stop="handleType(index + 1)" :class="['btn center', { active: index + 1 == type }]">
            <div class="ic">{{ item }}</div>
          </div>
          <div class="slider" :style="{ left: sliderLeft + 'px' }" ref="slider"></div>
        </div>
        <template v-if="type == 1">
          <div class="type space_around">
            <div @click="handlerevenueListType('1')" class="type_btn center" :class="revenueListPage.moneyType == '1' ? 'active' : ''">金币</div>
            <div @click="handlerevenueListType('2')" class="type_btn center" :class="revenueListPage.moneyType == '2' ? 'active' : ''">积分</div>
          </div>
          <div class="revenueList" @scroll="revenueListScroll">
            <div class="main_ii_top space_around">
              <div class="je">金额</div>
              <div class="yt">用途</div>
              <div class="zt">状态</div>
              <div class="sj">时间</div>
            </div>
            <div class="main_ii_center space_around" v-for="(item, index) in revenueList" :key="index">
              <div class="je" v-if="revenueListPage.moneyType == '1'">{{ (item?.amount || 0).toFixed(2) }}</div>
              <div class="je" v-else>{{ (item?.credits || 0).toFixed(2) }}</div>
              <div class="yt">{{ item.remark }}</div>
              <div class="zt">
                <div v-if="item.type == 0" style="color: red">消费</div>
                <div v-else :style="{ color: $main }">收入</div>
              </div>
              <div class="sj">{{ item.createTime }}</div>
            </div>
            <div class="over_text">{{ revenueListIsOver }}</div>
          </div>
        </template>
        <template class v-if="type == 2">
          <div class="main_iii_top space_around">
            <div class="zfje">金额</div>
            <div class="zfqd">渠道</div>
            <div class="zfdh">单号</div>
            <div class="zfsj">时间</div>
          </div>
          <div class="recharge_box" @scroll="rechargeScroll" v-if="rechargeList.length">
            <div class="main_iii_center space_around" v-for="(item, index) in rechargeList" :key="index">
              <div class="zfje"><price :price="item.goodsPrice" /></div>
              <div class="zfqd">
                {{ payType(item.payType) }}
              </div>
              <div class="zfdh">{{ item.orderId }}</div>
              <div class="zfsj">{{ item.createTime }}</div>
            </div>
            <div class="over_text">{{ rechargeRecordsIsover }}</div>
          </div>
        </template>
        <template v-if="type == 3">
          <div class="fnjie_box" v-if="userDecompseList.length" @scroll="userdecompseListScroll">
            <div class="fenjie_list">
              <goodsCard v-for="(item, index) in userDecompseList" :key="index" :goodsItem="item" :keyMap="{ levelImg: 'ornamentLevelImg', imageUrl: 'ornamentImgUrl', name: 'ornamentName', price: 'staticPrice' }" />
            </div>
            <div class="over_text">{{ userdecompseListIsover }}</div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { getUserLogList, userdecompseList, deliveryRecords, rechargeRecords } from '@/api/index';
import goodsCard from '@/components/goodsCard';

export default {
  props: ['tabType'],
  components: { goodsCard },
  data() {
    return {
      sliderLeft: 0,
      category: 10,
      type: 1,
      menuList: ['收支明细', '充值明细', '分解明细'],
      delivery: this.$route.query?.delivery || '',
      deliveryTabs: [
        { title: '申请提货', icon: 'el-icon-document', type: 1 },
        { title: '等待收货', icon: 'el-icon-time', type: 2 },
        { title: '提取完成', icon: 'el-icon-document-checked', type: 10 },
        // { title: '冻结中', icon: 'el-icon-document-checked', type: 12 },
      ],
      // 交易记录
      revenueTotal: 0,
      revenueList: [],
      revenueLoading: false,
      revenueListIsOver: '上拉加载更多',
      revenueListPage: {
        moneyType: 1,
        page: 1,
        size: 10,
      },
      // 充值记录
      rechargeTotal: 0,
      rechargeList: [],
      rechargeLoading: false,
      rechargeRecordsIsover: '上拉加载更多',
      rechargeRecordsPage: {
        page: 1,
        size: 10,
      },
      // 分解记录
      userDecompseList: [],
      userdecompseListIsover: '上拉加载更多',
      userdecompseListPage: {
        page: 1,
        size: 15,
      },
      // 提货账单
      deliveryRecordTotal: 0,
      deliveryRecordsList: [],
      deliveryRecordsIsOver: '上拉加载更多',
      deliveryRecordsPage: {
        page: 1,
        size: 14,
        statusList: [10],
      },
    };
  },
  mounted() {
    if (this.$mode == 'pc') return;
    if (this.delivery) {
      this.getlisttihuo(1);
    } else {
      this.getRevenueList(1);
    }
  },
  watch: {
    tabType: {
      handler(val) {
        if (val == 4) {
          this.getlisttihuo(1);
        }
        if (val == 5) {
          this.queryList(1); // 收支明细
          this.queryList(2); // 充值明细
        }
      },
      immediate: true,
    },
  },
  methods: {
    
    playAudio() {
      this.$store.commit('playAudio');
    },
    payType(type) {
      const wx = [4, 5, 8],
        ali = [1, 3, 6, 7];
      if (type == 2) return '卡密';
      if (wx.includes(type)) return '微信';
      if (ali.includes(type)) return '支付宝';
      return '卡密';
    },
    payStatus(status) {
      const patMap = { 0: '未付款', 1: '充值成功', '-1': '充值失败', 2: '未付款', 4: '充值成功' };
      return patMap[+status];
    },
    // 充值明细
    rechargeScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 5 >= scrollHeight-1) {
        if (this.rechargeRecordsIsover == '上拉加载更多') {
          this.rechargeRecordsPage.page++;
          let timer = setTimeout(() => {
            this.getRechangeList();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    userdecompseListScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 5 >= scrollHeight-1) {
        if (this.userdecompseListIsover == '上拉加载更多') {
          this.userdecompseListPage.page++;
          let timer = setTimeout(() => {
            this.getUserfenjie();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    // 提货信息
    deliveryRecordsScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 5 >= scrollHeight-1) {
        if (this.deliveryRecordsIsOver == '上拉加载更多') {
          this.deliveryRecordsPage.page++;
          let timer = setTimeout(() => {
            this.getlisttihuo();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    // 收支明细
    revenueListScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 5 >= scrollHeight-1) {
        if (this.revenueListIsOver == '上拉加载更多') {
          this.revenueListPage.page++;
          let timer = setTimeout(() => {
            this.getRevenueList();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    handlerevenueListType(res) {
      this.playAudio();
      this.revenueListPage.moneyType = res;
      this.getRevenueList(1);
    },
    getUserfenjie() {
      this.userdecompseListIsover = '加载中';
      userdecompseList(this.userdecompseListPage)
        .then(res => {
          const list = res?.data?.data?.list || [];
          this.userDecompseList.push(...list);
          if (list.length < this.userdecompseListPage.size) {
            this.userdecompseListIsover = '暂无更多';
          } else {
            this.userdecompseListIsover = '上拉加载更多';
          }
        })
        .catch(err => {
          console.log('catch', err);
        });
    },
    handleCategory(type) {
      this.playAudio();
      this.category = type;
      this.deliveryRecordsPage.statusList = [type];
      this.getlisttihuo(1);
    },
    handleType(type) {
      this.playAudio();
      this.type = type;
      const width = this.$refs.slider.offsetWidth;
      this.sliderLeft = width * (type - 1);
      this.queryList(type);
    },
    queryList(type) {
      if (type == 1) {
        this.getRevenueList(1);
      } else if (type == 2) {
        this.getRechangeList(1);
      } else if (type == 3) {
        this.userdecompseListPage.page = 1;
        this.userDecompseList = [];
        this.getUserfenjie();
      }
    },
    // 充值明细
    getRechangeList(page) {
      this.rechargeLoading = true;
      this.rechargeRecordsIsover = '加载中';
      if (page) this.rechargeRecordsPage.page = page;
      if (page == 1) this.rechargeList = [];
      rechargeRecords(this.rechargeRecordsPage).then(res => {
        this.rechargeLoading = false;
        const { list = [], total } = res?.data?.data || {};
        if (this.$store.state.mode == 'pc') {
          this.rechargeList = [...list];
          this.rechargeTotal = total || 0;
        } else {
          this.rechargeList.push(...list);
          this.rechargeRecordsIsover = list.length < this.rechargeRecordsPage.size ? '暂无更多' : '上拉加载更多';
        }
      });
    },
    // 收支明细
    getRevenueList(page) {
      this.revenueLoading = true;
      this.revenueListIsOver = '加载中';
      if (page) this.revenueListPage.page = page;
      if (page == 1) this.revenueList = [];
      getUserLogList(this.revenueListPage).then(res => {
        this.revenueLoading = false;
        const { list = [], total } = res?.data?.data || {};
        if (this.$store.state.mode == 'pc') {
          this.revenueList = [...list];
          this.revenueTotal = total || 0;
        } else {
          this.revenueList.push(...list);
          this.revenueListIsOver = list.length < this.revenueListPage.size ? '暂无更多' : '上拉加载更多';
        }
      });
    },
    getlisttihuo(page) {
      this.deliveryRecordsIsOver = '加载中';
      if (page) this.deliveryRecordsPage.page = page;
      if (page == 1) this.deliveryRecordsList = [];
      deliveryRecords(this.deliveryRecordsPage).then(res => {
        const { list = [], total } = res?.data?.data || {};
        if (this.$store.state.mode == 'pc') {
          this.deliveryRecordTotal = total || 0;
          this.deliveryRecordsList = [...list];
        } else {
          this.deliveryRecordsList.push(...list);
          this.deliveryRecordsIsOver = this.deliveryRecordsPage.size > list.length ? '暂无更多' : '上拉加载更多';
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.taps {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 35%;
    min-width: 200px;
    margin: 0 auto;

    @media @max750 {
      width: 60%;
      padding-bottom: 20px;
    }

    .tap {
      .sc(20px, #fff);
      padding: 10px 15px;
      border-radius: 10px;
      border: 1px solid #606365;
      background: rgba(#606365, 0.1);
      cursor: pointer;
    }

    .active {
      border: 1px solid @main;
      background: rgba(@main, 0.1);
    }
  }
.personal_pc {
  // 提货信息
  .delivery_tabs {
    .flex-a-center;
    margin: 24px 0 34px;
    & > button {
      .sc(16px, #fff);
      .btn-style(140px, 40px);
      margin-right: 20px;
      border-radius: 30px;
      span {
        padding-left: 4px;
      }
      &.active {
        .btn-shadow;
        border: none;
        height: 34px;
        line-height: 34px;
        background: rgba(@main, 0.1);
      }
    }
  }
  .delivery_list {
    .grid(7, 8px);
    margin-bottom: 48px;
  }
  // 充值明细
  .records_list {
    display: flex;
    justify-content: space-between;
    .recharge_records,
    .revenue_records {
      width: 49%;
      margin-top: 70px;
      .flex-column;
      h2 {
        width: 160px;
        .sc(20px, #DADADA);
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #fff;
      }
    }
  }
}

.personal_mobile {
  left: 0;
  right: 0;
  .flex-column;
  top: @headerH;
  bottom: @footerH;
  position: absolute;
  .tab {
    width: 70%;
    height: 34px;
    font-size: 14px;
    color: #727477;
    margin: 0 auto 14px;
    background: #323436;
    border-radius: 70px;
    position: relative;
    transition: all 0.2s linear;
    .btn {
      width: 33%;
      height: 100%;
    }
    .active {
      color: @main;
    }
    .ic {
      position: relative;
      z-index: 2;
    }
    .slider {
      position: absolute;
      width: 33.3%;
      height: 34px;
      box-sizing: border-box;
      border: 1px solid @main;
      border-radius: 70px;
      z-index: 1;
      transition: left 0.2s linear;
    }
  }
  .main_i_top {
    margin: 0 @mSpace;
    padding: 14px 24px;
    border-radius: 10px;
    background: #101115;
    box-sizing: border-box;
    .grid(3, 24px);
    .btn {
      width: 100%;
      height: 28px;
      color: #888;
      font-size: 12px;
      border-radius: 35px;
      border: 1px solid #888;
      .flex-center;
      span {
        padding-left: 2px;
      }
    }
    .active {
      color: @main;
      border-color: @main;
    }
  }
  .main_ii_top {
    width: 100%;
    background: #101115;
    border-radius: 8px;
    opacity: 1;
    color: #727477;
    height: 50px;
    font-size: 13px;
    & > div {
      text-align: center;
    }
  }
  .main_ii_center {
    width: 100%;
    background: #101115;
    border-radius: 7px;
    margin-top: 6px;
    color: #ffffff;
    height: 50px;
    font-size: 12px;
    & > div {
      text-align: center;
    }
  }
  .je,
  .yt,
  .zt {
    width: 97px;
  }
  .sj {
    width: 160px;
  }
  .main_iii_top {
    margin: 0 @mSpace;
    background: #101115;
    border-radius: 8px;
    color: #727477;
    opacity: 1;
    font-size: 12px;
    height: 40px;
    & > div {
      text-align: center;
    }
  }
  .main_iii_center {
    width: 100%;
    background: #101115;
    border-radius: 7px;
    font-size: 12px;
    margin-top: 6px;
    height: 40px;
    & > div {
      text-align: center;
    }
  }
  .zfje,
  .zfqd {
    width: 70px;
  }
  .zfdh,
  .zfsj {
    .ell;
    width: 170px;
    margin: 0 2px;
  }
  .common_price {
    justify-content: center;
    ::v-deep {
      img {
        width: 12px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .recharge_box {
    flex: 1;
    overflow-y: auto;
    padding: 0 @mSpace;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
  }
  .fnjie_box {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 @mSpace;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
    .fenjie_list {
      .grid(3, 6px);
    }
  }
  .delivery_box {
    flex: 1;
    margin-top: 10px;
    padding: 0 @mSpace;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
    .delivery_list {
      .grid(3, 6px);
    }
  }
  .revenueList {
    flex: 1;
    overflow-y: auto;
    padding: 0 @mSpace;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
    & > div {
      flex-shrink: 0;
    }
  }
  .type {
    width: 145px;
    height: 30px;
    margin: 0 auto 14px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    .type_btn {
      width: 49%;
      opacity: 1;
      transition: all 0.3s linear;
      color: rgba(114, 116, 119, 1);
      height: 30px;
      font-size: 12px;
    }
    .active {
      border: 1px solid @main;
      border-radius: 70px 70px 70px 70px;
      color: @main;
    }
  }
}
</style>
